import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import HomeIcon from '@material-ui/icons/Comment';

import { Link as RouterLink } from 'react-router-dom';

import Button from '@material-ui/core/Button';

import firebase from './firebase';
import { Subject } from 'rxjs';
import { distinctUntilChanged, debounceTime, filter, tap } from 'rxjs/operators';
import { Controlled as CodeMirror } from 'react-codemirror2';

import './NotePad.css';
import 'codemirror/mode/xml/xml';
import 'codemirror/mode/javascript/javascript';
import 'codemirror/mode/python/python';
import 'codemirror/mode/markdown/markdown';
import 'codemirror/mode/gfm/gfm';
import 'codemirror/mode/shell/shell';

class NotePad extends React.Component {
  constructor(props) {
    super(props);
    this.db = firebase.firestore();
    this.codes = this.db.collection('codes');
    this.codeArea = React.createRef();

    this.state = {
      id: '',
      url: '',
      code: '',
      init: false,
      cursor: { line: 0, ch: 0 },
      loading: false,
      write: false,
      readOnly: false,
      applyNewData: false
    };


    this.subject = new Subject();

    const current_url = this.props.location.pathname;
    const arr_url = current_url.split('/');
    this.slug = arr_url[1];
    this.markdownSlug = `/markdown/${this.slug}`;

    if (this.slug === '') {
      window.location.href = this.randomStr(
        7,
        '123456789abcdefghijklmnopqrstuvwxyz'
      );
    }
  }

  loadOrCreate() {
    var query = this.codes.where('url', '==', this.slug);
    query
      .get()
      .then(querySnapshot => {
        // data exists
        if (querySnapshot.size > 0) {
          querySnapshot.forEach(doc => {
            const data = doc.data();
            this.unsubscribe = this.codes.doc(doc.id).onSnapshot(this.onCollectionUpdate);
            this.setState(state => ({ ...this.state, ...data, id: doc.id, init: false, loading: false }));
          });
        }
        // new data
        else {
          this.codes
            .add({ url: this.slug, code: '', timestamp: firebase.firestore.FieldValue.serverTimestamp() })
            .then(data =>
              this.setState(state => {
                const new_state = ({ ...this.state, id: data.id, url: this.slug, init: true, loading: false, code: '\n'.repeat(50) });
                return new_state
              })
            )
        }
      })
      .catch(function (error) {
        console.error('Error getting documents: ', error);
      });

  }

  componentDidMount() {
    this.loadOrCreate()
    this.setState(state => ({ ...state, loading: true }));


    this.subject
      .asObservable()
      .pipe(
        filter(_ => this.state.write),
        debounceTime(1000),
        distinctUntilChanged(),
      )
      .subscribe(data => {

        if (this.state.url !== '') {
          var sfDocRef = this.db.collection('codes').doc(this.state.id);

          this.db
            .runTransaction(function (transaction) {
              return transaction.get(sfDocRef).then(function (sfDoc) {
                if (!sfDoc.exists) console.error('Document does not exist!');
                transaction.update(sfDocRef, { code: data });
              });
            })
            .then(_ => {
              this.setState(state => ({ ...state, loading: false, write: false }));
            })
            .catch(error => console.log('Transaction failed: ', error));
        }
      });
  }

  onCollectionUpdate = doc => {
    this.setState(state => ({ ...state, loading: true, applyNewData: true }));
    //s'il n'y a pas d'écriture en cours
    let data = {};
    if (!doc.metadata.hasPendingWrites) {
      if (doc.data().url === this.slug) {
        data = { ...doc.data(), id: doc.id };

        let remote_data_array = doc.data().code.split("\n")
        let current_data_array = this.state.code.split("\n")
        if (remote_data_array.length <= this.state.cursor.line) {
          remote_data_array[this.state.cursor.line - 1] = current_data_array[this.state.cursor.line - 1]
        }
        this.setState(state => ({ ...state, code: data.code, init: false, cursor: { ...state.cursor },applyNewData: false, loading: false, write: false }));
      }
    }
  };



  beforeChange = (editor, data, value) => {
    if (!this.state.applyNewData) {
      this.setState(state => {
        this.subject.next(value);
        return ({ ...state, code: value, init: false, applyNewData: false, write: true, cursor: { ...state.cursor } })
      });
    }
  };

  cursorChange = (editor, data) => {

    this.setState(state => {
      const cursor = this.state.applyNewData ? this.state.cursor : data;
      return ({ ...state, cursor })
    })
  };

  randomStr(len, arr) {
    var ans = '';
    for (var i = len; i > 0; i--) {
      ans += arr[Math.floor(Math.random() * arr.length)];
    }
    return ans;
  }

  componentWillUnmount() {
    this.unsubscribe();
  }
  render() {
    const value = this.state.code;
    return (
      <>
        <AppBar position="static">
          <Toolbar>
            <IconButton edge="start" color="inherit" aria-label="menu" href="/">
              <HomeIcon />
            </IconButton>
            <Typography variant="h6" style={{ flex: 1, flexDirection: 'row' }}>
              Notepad.re {this.state.write ? '...' : ''}
            </Typography>
            <Button
              color="inherit"
              component={RouterLink}
              to={this.markdownSlug}
            >
              toHTML
            </Button>
          </Toolbar>
        </AppBar>
        <CodeMirror
          ref={this.codeArea}
          value={value}
          cursor={this.state.cursor}
          options={{
            lineNumbers: true,
            mode: 'gfm',
            autofocus: true,
            autoClearEmptyLines: false,
            autoCloseBrackets: true
          }}
          onBeforeChange={this.beforeChange}
          onCursor={this.cursorChange}
        />
      </>
    );
  }
}
export default NotePad;
