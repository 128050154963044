import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import firebase from './firebase';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
// import Icon from '@material-ui/core/Icon';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import HomeIcon from '@material-ui/icons/Comment';



import {
    Link
  } from "react-router-dom";

class Admin extends React.Component {

    constructor(props) {
        super(props);
        this.state = { codes: [] };
        this.loadCodes();
        this.deleteCode = this.deleteCode.bind(this);
    }

    componentDidMount() {

    }

    deleteCode(code) {
        firebase.firestore().collection("codes").doc(code.id).delete().then(() => {
            this.loadCodes();
        }).catch(function (error) {
            console.error("Error removing document: ", error);
        });
    }

    loadCodes() {
        firebase.firestore().collection('codes').get()
            .then(querySnapshot => {
                let arr = [];
                if (querySnapshot.size > 0) {
                    querySnapshot.forEach(doc => {
                        const data = doc.data();
                        this.setState(state => {
                            arr.push({ ...data, id: doc.id });
                        });
                    });
                }
                return Promise.resolve(arr);
            })
            .then(data => {
                this.setState(state => {
                    return { codes: data };
                });
            });
    }

    render() {

        return (
            <>
            <AppBar position="static">
                <Toolbar>
                    <IconButton edge="start" color="inherit" aria-label="menu" href="/">
                        <HomeIcon />
                    </IconButton>
                    <Typography variant="h6" >
                        Notepad.re
                    </Typography>
                </Toolbar>
            </AppBar>

                <TableContainer component={Paper}>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>Url</TableCell>
                                <TableCell>Length</TableCell>
                                <TableCell align="right"></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.codes.map(data => (
                                <TableRow key={data.id} hover>
                                    <TableCell component="th" scope="row">
                                    <Link to={`/${data.url}`}>{data.url}</Link>
                                    </TableCell>
                                    <TableCell>
                                        {data.code.length}
                                    </TableCell>
                                    <TableCell align="right">
                                        <Button variant="contained" color="secondary" onClick={e => this.deleteCode(data,e)}><DeleteIcon/></Button>            
                                        
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </ >
        );
    }
}
export default Admin;
