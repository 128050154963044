import React from 'react';
import firebase from './firebase';
import { Markdown } from 'react-showdown';
import {Converter} from 'react-showdown';
import Paper from '@material-ui/core/Paper';


class MarkDown extends React.Component {

    constructor(props) {
        super(props);
        this.codes = firebase.firestore().collection('codes');
        this.state = {
            id: '',
            url: '',
            code: '',
        };

        const current_url = this.props.location.pathname;
        const arr_url = current_url.split('/');
        this.slug = arr_url[2];
        this.converter = new Converter({ghCodeBlocks: true});
    }

    componentDidMount() {
        var query = this.codes.where("url", "==", this.slug);
        this.unsubscribe = this.codes.onSnapshot(this.onCollectionUpdate);

        query.get().then(querySnapshot => {
            if (querySnapshot.size > 0) {
                querySnapshot.forEach(doc => {
                    const data = doc.data();
                    this.setState(state => {
                        const newState = { ...this.state, ...data, id: doc.id, init: false };
                        return newState;
                    });
                });
            }
            else {
                this.codes
                    .add({ url: this.slug, code: '' })
                    .then(data => this.setState(state => ({ ...this.state, id: data.id, url: this.slug, init: true, code: '\n'.repeat(50) })));
            }
        })
        .catch(function (error) {
            console.error("Error getting documents: ", error);
        });
    }

    onCollectionUpdate = (querySnapshot) => {
        let data = {}
        // var source = querySnapshot.metadata.hasPendingWrites ? "Local" : "Server";
        if (!querySnapshot.metadata.hasPendingWrites) {

            querySnapshot.forEach((doc) => {
                if (doc.data().url === this.slug) {
                    console.log(doc.data())
                    data = { ...doc.data(), id: doc.id };
                }

            });

            this.setState(state => {
                return ({ ...this.state, code: data.code, init: false });
            })
        }

    }
    render() {
        const markdown = this.state.code;
        var reactElement = this.converter.convert(markdown);

        return (
            <>
            <Paper variant="outlined" >
                {reactElement}
            </Paper>
            </>
        );
    }
}
export default MarkDown;
