import React from 'react';

import NotePad from './NotePad';
import Admin from './Admin';
import MarkDown from './MarkDown';
import SignInForm from './SignInForm';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  withRouter,
} from "react-router-dom";

import './App.css';
import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/material.css';
import firebase from './firebase';
import "firebase/auth";



class App extends React.Component {


  componentWillMount(){
    firebase.auth().onAuthStateChanged(function(user) {
      if (user) {
        // console.log(user)
      } else {
        // console.log('not logged')
      }
    });
    
  }


  render() {
    return (
      <>
        <Router>
          <Switch>
            <Route path="/markdown">
              {withRouter(props => <MarkDown {...props} />)}
            </Route>
            <Route path="/admin">
              {withRouter(props => <Admin {...props} />)}
            </Route>
             <Route path="/signinform">
              {withRouter(props => <SignInForm {...props} />)}
            </Route>
            {/* <Route path="/signin">
              {withRouter(props => <SignIn {...props} />)}
            </Route> */}
            <Route path="/">
              {withRouter(props => <NotePad {...props} />)}
            </Route>

          </Switch>
        </Router>
      </>
    );
  }
}

export default App;
