import * as firebase from 'firebase/app';
import 'firebase/firestore';


const config = {
    apiKey: "AIzaSyDNNvz9zgMQX3QZyZqJLmbW9MkSl7nFYcI",
    authDomain: "npad-5d6b9.firebaseapp.com",
    projectId:'npad-5d6b9'
};
firebase.initializeApp(config);

firebase.firestore();

export default firebase;